import { AfterViewInit, Component, OnInit } from '@angular/core';
import { LayoutService } from '../../core';
import KTLayoutFooter from '../../../../../../assets/js/layout/base/footer';

@Component({
  selector: 'app-footer-large',
  templateUrl: './footer-large.component.html',
  styleUrls: ['./footer-large.component.scss'],
})
export class FooterLargeComponent implements OnInit, AfterViewInit {
  footerContainerCSSClasses: string;
  currentYear: string;
  currentVersion = 'v3.2.18';

  constructor(private layout: LayoutService) {
    const currentDate = new Date();
    this.currentYear = currentDate.getFullYear().toString();
  }

  ngOnInit(): void {
    this.footerContainerCSSClasses = this.layout.getStringCSSClasses('footer_container');
  }

  ngAfterViewInit() {
    // Init Footer
    KTLayoutFooter.init('kt_footer');
  }
}
