import { Environment } from '@abp/ng.core';

const baseUrl = 'https://dev.algoleague.com';

export const environment = {
  production: true,
  application: {
    baseUrl,
    name: 'Ojs',
    logoUrl: '',
  },
  oAuthConfig: {
    issuer: 'https://dev.identity.algoleague.com',
    redirectUri: baseUrl,
    clientId: 'Ojs_App',
    responseType: 'code',
    scope: 'offline_access openid profile role email phone Ojs',
  },
  apis: {
    default: {
      url: 'https://dev.api.algoleague.com',
      rootNamespace: 'Inzva.Ojs',
    },
    comments: {
      url: 'https://dev.discussion.algoleague.com',
    },
    notifications: {
      url: 'https://dev.notification.algoleague.com',
    },
  },
  validation: {
    minLengthShortText: 3,
    maxLengthShortText: 360,
    minLengthLongText: 10,
    maxLengthLongText: 500,
    minAgeOfUser: 10,
    maxAgeOfUser: 100,
    widthProfilePicture: 200,
    heightProfilePicture: 200,
  },
  recaptchaSiteKey: '6LdOo-QcAAAAAJsxLyzkBpJxNjEbcJ-IoE0qG9mY',
  getStartProblemSlugs: ['solve-this-first'],
  randomBackgroundImage: ['/assets/media/bg/bg-getstart.jpg'],
  unsplashClientId: '6zBpiAtHT0FPSvPcq9mD8PaIio2rz_94S7P2ocVhW0A',
  unsplashCollectionId: 'YvwtzbWU9Nw',
  version: 'v3.2.18.0-dev',
} as Environment;
